<template>
    <div
        v-if="false"
        class="badge border bg-light text-primary locale-icon"
        :style="`background-color: oklch(95% 0.15 ${GROUPS_COLOUR_CODE[locale.family]}) !important`"
    >
        {{ locale.symbol || '&nbsp;' }}
    </div>
</template>

<script>
const GROUPS_COLOUR_CODE = {
    // indo-european
    germanic: 0,
    romance: 300,
    slavic: 280,
    // uralic
    finnish: 240,
    // turkic
    turkic: 200,
    // austroasiatic
    vietic: 160,
    // afro-asiatic
    semitic: 40,
    // japonic
    japonic: 80,
    // koreanic
    koreanic: 120,
    // sino-tibetan
    sinitic: 140,
    // constructed
    constructed: 160,
};

export default {
    props: {
        locale: {},
    },
    data() {
        return {
            GROUPS_COLOUR_CODE,
        };
    },
};
</script>

<style scoped>
.locale-icon {
    width: 2.5em;
}
</style>
